import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { RentArticleSummary } from "../../models/articles";
import { RangePickerController } from "@ct-react/calendar";
import { FormattedMessage, useIntl } from "react-intl";
import { Period } from "@ct-react/core";
import moment, { Moment } from "moment";
import { gql, makeReference, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { RentBookingOptions } from "../../models/date";
import { PrimaryButton } from "../button";
import gsap from "gsap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faChevronDown, faCircleInfo, faUsers, faXmark } from "@fortawesome/free-solid-svg-icons";
import { CART_ITEM_FIELDS, CART_PRICE_FIELDS, PRICE_FIELDS } from "@shared/fragments";
import { ButtonBox } from "../wrapper/common";
import { LayoutContext } from "../../contexts/layout";
import { useVisitor } from "@ct-react/visitor";
import { FilterModuleContext } from "../../contexts/filterModule";
import parse from 'html-react-parser';
import Loader from "../loader";
import { BookingBoxFormCallback } from "./form-callback";
import "./bookingBox.scss";

type BookingBoxProps = {
    props:RentArticleSummary,
    changeForm:()=>void,
    setPrice:Dispatch<SetStateAction<any>>
};

const BOOKING_OPTION = gql`
    query GetBookingOption($articleId : ID!){
        bookingAccommodationOptions(articleId: $articleId) {
            period
            checkInConfigs {
                day
                dayOffset
                checkOutConfigs {
                    day
                    bookable
                }
            }
        }
    }
`;

const BOOKING_PRICE = gql`
    query GetBookingPrice($articleId : ID! $checkin : Date! $checkout : Date! ){
        bookingAccommodationPrice(
            articleId: $articleId
            checkIn: $checkin
            checkOut: $checkout
          ) {
            checkIn
            checkOut
            priceOnDemand
            bookable
            price {
                currency
                amount
                original
                isDiscounted
            }
            hasExtras
            extras {
            id
            type
            amount
            }
          }
    }
`;

const BOOKED_DATE = gql`
    query GetBookedDate($articleId: ID!){
        bookingAccommodationBookedDates(articleId: $articleId)
    }
`;

const CHECK_OPTIONS_GQL_DATA = gql`
    ${PRICE_FIELDS}
    query CheckOptions($item: RentalAccommodationOptionablesFilterInput!) {
    options: cartCheckOptions(item: { rentalAccommodation: $item } type: BENEFIT) {
        ... on OptionableBenefit {
            id
            title { value }
            description { value }
            criteria { quantity }
            forceSelection
            grid {
                quantity
                price {...PriceField}
            }
        }
        ... on OptionableTouristTax {
            id
            grid {
                ageRange
                price {...PriceField}
            }
        }
    }}
`;

const ADD_TO_CART_GQL_MUT = gql`
  ${CART_ITEM_FIELDS}
  ${CART_PRICE_FIELDS}
  mutation AddToCart($sessionId: String, $userId: String, $item: AddRentalAccommodationCartItemInput!, $options: [AddCartItemOptionInput!]) {
    cartAddItem(sessionId: $sessionId, userId: $userId, item: { rentalAccommodation: $item }, options: $options) {
      cartId itemId item { ...CartItemFields } fullPrice { ...CartPriceFields }
    }
  }
`;

const TOURIST_TAX = gql`
    query getTourist_tax($articleId: ID!){
        bookingAccommodationTouristTaxGrid(articleId: $articleId) {
            id
            grid {
            ageCategory
            ageRange
            price{
                amount
                currency
                }
            }
        }
    }
`;

const BookingBox:FunctionComponent<BookingBoxProps> = ({props,changeForm,setPrice})=>{
    const layout = useContext(LayoutContext)
    const intl = useIntl();

    //retrieve data from search
    const {searchData,dispatchSearchData} = useContext(FilterModuleContext);

    //Calendar render
    const [ dates, setDates ] = useState<Period>({});
    const [bookingData,setBookingData] = useState<RentBookingOptions|null>(null);
    const [bookedDate,setBookedDate] = useState<any>(null);
    const [range,setRange] = useState<Period>({});
    const [pickerFocus,setPickerFocus] = useState<any>("start");
    const [bookingPrice,setBookingprice] = useState<any>(null);
    const [fixedCalendarOpen,setFixedCalendarOpen] = useState<boolean>(false);
    // const [closestBookedDate, setClosestBookedDate] = useState<Moment|null>(null);
    const [calendarOpen,setCalendarOpen] = useState<boolean>(false);
    const [needContact,setNeedContact] = useState<boolean>(false);
    const { sessionId, userId , isLoggedIn } = useVisitor();

    //Nbr Tourist
    const [nbrTouristWindow,setNbrTouristWindow] = useState<boolean>(layout.includes("smartphone") ? true : false);
    const [disabled, setDisabled] = useState<boolean>(false);

    //Supplementary prestation
    const [cartOptions, setCartOptions] = useState<any|null>(null);
    const [showPrestation, setShowPrestation] = useState<boolean>(false);
    const [prestations, setPrestations] = useState<any>([]);
    const [prestationsPrice, setPrestationsPrice] = useState<number>(0);

    //Taxes by person
    const [ touristTaxOptionId, setTouristTaxOptionId ] = useState<string | undefined>(undefined);
    const [touristTax, setTouristTax] = useState<any>(null);
    const [totalTourist, setTotalTourist] = useState<number>(0);
    const [touristTaxPrice, setTouristTaxPrice] = useState<number>(0);

    //Services taxe
    const [servicePercentage, setServicePercentage] = useState<null | number>(null);

    //Form
    const [formReady,setFormReady] = useState<boolean>(false);
    const [succeeded, setsucceeded] = useState<boolean | null>(null);

    const articleData = {...props};

    const advancedBlockageDisplay = {
        onlineRestrictedLabel : intl.formatMessage({ id:"calendar-onRequestOnly", defaultMessage: "Sur demande uniquement"})
    };

    const title = ()=>{
        if(!range.start&&!range.end){
            return intl.formatMessage({id:"calendar-select-arrival", defaultMessage: "Sélectionnez la date d'arrivée"});
        }else if(!!range.start && !range.end){
            return intl.formatMessage({id:"calendar-select-departure", defaultMessage: "Sélectionnez la date de départ"});
        }else if(!!range.start&&!!range.end){
            const diff = range.end.diff(range.start,"days");
            return intl.formatMessage({
                id: "calendar-number-of-night",
                defaultMessage: "{diff, plural, =1 {# nuit} other {# nuits}}"
              }, {diff})
        }
    };

    const subtitle = ()=>{
        if(!range.start&&!range.end){
            return <FormattedMessage
            id="searchmodule-choose-dates"
            defaultMessage="Quelles sont vos dates? <span> Du Samedi au Samedi uniquement</span>"
            values={{
              span: (...chunks) => <span>{chunks}</span>,
            }}
            />;
        }else if(!!range.start && !range.end){
            return `${range.start.format("D MMM YY")} - `
        }else if(!!range.start&&!!range.end){
            return `${range.start.format("D MMM YY")} - ${range.end.format("D MMM YY")}`
        }
    };

    //Fetching booking data

    useQuery(BOOKING_OPTION,{
        variables:{
            articleId:articleData.id
        },
        onCompleted:(data)=>{
            setBookingData(data.bookingAccommodationOptions)
            const datesRange:Period = {
                start:moment(data.bookingAccommodationOptions.period[0]),
                end:moment(data.bookingAccommodationOptions.period[1])
            }
            setDates(datesRange)
        }
    });

    const {refetch} = useQuery(BOOKED_DATE,{
        variables:{
            articleId:articleData.id
        },
        pollInterval: 30 * 1000,
        onCompleted:(data)=>{
            setBookedDate(data.bookingAccommodationBookedDates)
        }
    });

    const [getBookingPrice] = useLazyQuery (BOOKING_PRICE,{
        onCompleted:(data)=>{
            setBookingprice(data.bookingAccommodationPrice);
            setPrice(data.bookingAccommodationPrice);
            if(!data.bookingAccommodationPrice.bookable){
                checkAvailaibilities()
            }
            if(data.bookingAccommodationPrice.hasExtras){
                const TaxService = data.bookingAccommodationPrice.extras.find((e:any)=>e.type === "CUSTOMER_CHARGE");
                setServicePercentage(Math.round((TaxService.amount * 100 / data.bookingAccommodationPrice.price.amount + Number.EPSILON) * 100) / 100);
            }

        }
    });

    useEffect(()=>{
        setFormReady(false);
        if (pickerFocus===null){ //launch request
            getBookingPrice({
                variables:{
                    articleId:articleData.id,
                    checkin:range.start?.format("YYYY-MM-DD"),
                    checkout:range.end?.format("YYYY-MM-DD")
                }
            });
            getcartOptions({
                variables: {
                    item: {
                        articleId: articleData.id,
                        checkIn: range.start,
                        checkOut: range.end
                    }
                }
            });
        }
    },[pickerFocus]);

    //Data Mutation

    onError(({ graphQLErrors, networkError, operation, forward }) => {
        if (graphQLErrors) {
          console.error(graphQLErrors)
        }

        if (networkError) {
          console.error(`[Network error]: ${networkError}`);
        }
    });

    const [ addToCart,  { loading: actionLoading } ] = useMutation(ADD_TO_CART_GQL_MUT, {
        update: (cache, { data: { cartAddItem } }) => {
          cache.modify({
            id: cache.identify(makeReference(`Cart:${cartAddItem.cartId}`)),
            fields: {
              items: (cached = []) => [ ...cached, cartAddItem.item ],
              itemsCount: (cached = 0) => cached + 1,
              fullPrice: _ => cartAddItem.fullPrice
            }
          })
        },
        refetchQueries: [ "CartBadge" ],
        awaitRefetchQueries: true,
        onError:(error)=>console.error(error)
    });

    //Calendar

    useEffect(()=>{ //set calendar date if was choosen from search
        if(searchData.stay.from! && searchData.stay.to!){
            setRange({
                start:moment(searchData.stay.from),
                end:moment(searchData.stay.to)
            });
            setPickerFocus(null);
            getBookingPrice({
                variables:{
                    articleId:articleData.id,
                    checkin:moment(searchData.stay.from),
                    checkout:moment(searchData.stay.from)
                }
            });
            getcartOptions({
                variables: {
                    item: {
                        articleId: articleData.id,
                        checkIn: moment(searchData.stay.from),
                        checkOut: moment(searchData.stay.from)
                    }
                }
            });
        }
    },[]);

    const isBlockedDate = (day:Moment)=>{
        //set arrival date on calendar
        const isCurrentDate = (date:any)=> date.day === day.format("YYYY-MM-DD");
        const isCurrentBookedDate = (date:any)=> date === day.format("YYYY-MM-DD");
        const isBiggerthen = (date:any)=> moment(date).isAfter(range.start) && day.isAfter(date.day);

        const currentDate = bookingData?.checkInConfigs.some(isCurrentDate);
        const isBookedDate = bookedDate?.some(isCurrentBookedDate);
        const closestBookedDate = bookedDate?.find(isBiggerthen);

        //set possible departure according to selected arrival
        if(pickerFocus==="end"){
            const selectedDay = bookingData?.checkInConfigs.filter((checkIn)=>checkIn.day === range.start?.format("YYYY-MM-DD"))[0].checkOutConfigs;
            if(!selectedDay) return false;
            if(isBookedDate) {
                return "booked";
            }
            const isBookable = selectedDay.find(isCurrentDate);
            if(!!closestBookedDate && day.isSameOrAfter(closestBookedDate)) return true; // disable booking if booked date before
            if(!!isBookable && !isBookable.bookable) return "online-restricted"; // bookable only
            if(!!isBookable && isBookable.bookable) return false; // totaly bookable

            return true;
        }

        if(isBookedDate) return "booked";
        return !currentDate; //if date not present we return true, if present false
    };

    const deleteRange = ()=>{
        setRange({});
        setPickerFocus("start");
        setBookingprice(null);
        setFormReady(false);
        setNeedContact(false);
        setPrice(null);
    };

    //POP UP & Go to Cart
    const goToBasket = ()=>{
        if(!dates.start || !dates.end){
            setPickerFocus("start");
            return;
        }
        addToCart({
            variables: {
                ...(!userId) && { sessionId },
                ...(!!userId) && { userId },
                item: {
                    articleId : articleData.id,
                    price: {
                        currency: bookingPrice.price.currency,
                        amount: bookingPrice.price.amount,
                        original: bookingPrice.price.original || bookingPrice.price.amount
                    },
                    checkIn: range.start!.format("YYYY-MM-DD"),
                    checkOut: range.end!.format("YYYY-MM-DD")
                },
                options: [ ...prestations, extractTouristForMutation(touristTax) ]
            },
        })
        .then(()=>setsucceeded(true))
        .catch((err)=>{
            setsucceeded(false);
        })
        .finally(()=>{ //Clean calendar
            setPickerFocus("start");
            setRange({});
            refetch();
        });
    };

    const PopUpAddToCart = useMemo(()=>{
        if(succeeded != null) return <BookingBoxFormCallback succeeded={succeeded} setState={()=>setsucceeded(null)}/>
        return <></>
    },[succeeded]);

    //Check Availabilities of journey

    const checkAvailaibilities = ()=>{
        if(bookingPrice === null) return;
        if(!bookingPrice.bookable){
            //can't be booked on these dates
            setNeedContact(true);
            setFormReady(false);
        }else if(articleData.features.minGuests > totalTourist){
            //not enough people
            setFormReady(false);
        }else if(articleData.features.maxGuests! < totalTourist){
            //too many people
            setFormReady(false);
        }else{
            setFormReady(true);
        }
    };

    const displayBookingInfo = useMemo(()=>{
        if(bookingPrice === null) return;
        if(!bookingPrice.bookable){
            //can't be booked on these dates
            return intl.formatMessage({id:"booking-on-demand-need-contact", defaultMessage: "Réservation sur demande, contactez-nous ou modifier vos dates"});
        }else{
            const nbrOfNight = range.end!=undefined&&range.end.diff(range.start,"days") || 0;
            let total = prestationsPrice? bookingPrice.price.amount + prestationsPrice : bookingPrice.price.amount;
            total = total + (touristTaxPrice*nbrOfNight);
            const amount = intl.formatNumber(total, {style: 'currency', currency: bookingPrice.price.currency});
            if(bookingPrice.price.isDiscounted){
                let originalPrice = prestationsPrice? bookingPrice.price.original + prestationsPrice : bookingPrice.price.original;
                originalPrice = originalPrice + (touristTaxPrice*nbrOfNight);
                let charge = total * (servicePercentage || 0) /  100;

                    return(
                    <>
                        <p>{intl.formatMessage(
                            {id: "total-Reductprice-nights", defaultMessage: "Prix pour {diff, plural, =1 {# nuit} other {# nuits}}"},
                            {
                                diff: nbrOfNight
                            }
                            )} <span>{originalPrice} {bookingPrice.price.currency}</span></p>
                        <p>{intl.formatMessage({id: "reduction", defaultMessage: "Réduction"})} <span className="discount">-{originalPrice - total} {bookingPrice.price.currency}</span></p>
                        {
                            bookingPrice.hasExtras && !!servicePercentage && (
                                <p data-gloss={intl.formatMessage({id:"booking-charge-info", defaultMessage: "Ces frais participent au fonctionnement de notre plate-forme."})}>
                                    {intl.formatMessage({id: "customer-charge", defaultMessage: "Frais de service"})} <span>{total * servicePercentage /  100}</span>
                                </p>
                            )
                        }
                        <p className="total">{intl.formatMessage({id: "total", defaultMessage: "Total"})} <span>{total + charge} {bookingPrice.price.currency}</span></p>
                    </>
                )
            }else if(bookingPrice.hasExtras && !!servicePercentage){
                let charge = total * servicePercentage /  100;
                return(
                    <>
                        <p>{intl.formatMessage(
                            {id: "total-Reductprice-nights", defaultMessage: "Prix pour {diff, plural, =1 {# nuit} other {# nuits}}"},
                            {
                                diff: nbrOfNight
                            }
                            )} <span>{total} {bookingPrice.price.currency}</span></p>
                        <p data-gloss={intl.formatMessage({id:"booking-charge-info", defaultMessage: "Ces frais participent au fonctionnement de notre plate-forme."})}>
                            {intl.formatMessage({id: "customer-charge", defaultMessage: "Frais de service"})} <span>{charge}</span>
                        </p>
                        <p className="total">{intl.formatMessage({id: "total", defaultMessage: "Total"})} <span>{total + charge} {bookingPrice.price.currency}</span></p>
                    </>
            );
            }else{
                return(
                        <>
                            <p>{intl.formatMessage({
                                id:"total-price-for-nights", defaultMessage: `Total pour {diff, plural, =1 {# nuit} other {# nuits}} : {amount}`},
                                {
                                    amount:amount ,
                                    diff: nbrOfNight
                                }
                                )}
                            </p>
                        </>
                );
            }
        }
    },[prestationsPrice,totalTourist,bookingPrice])

    const resetForm = ()=>{
        setFormReady(false)
    };

    //conditional rendering for output button /check availabilities || book || contact
    const renderBrowserOuputButton = useMemo(()=>{
        if(needContact || !bookingPrice?.bookable) return(
            <PrimaryButton
                value={intl.formatMessage({ id:"btn-contact-us", defaultMessage: "Nous contacter"})}
                setState={()=>changeForm()}
                squared={true}
                disabled={!!pickerFocus?true:false}
            />
        );
        if(formReady) return (
            <PrimaryButton
                value={intl.formatMessage({id:"btn-book", defaultMessage: "Réserver"})}
                setState={()=>goToBasket()}
                squared={true}
                disabled={(!!pickerFocus||totalTourist===0)? true : false}
            />
        );
        if(!formReady && totalTourist > 0) return(
            <PrimaryButton
                value={intl.formatMessage({id:"btn-check-availaibility", defaultMessage: "Vérifier la disponibilité"})}
                setState={()=>checkAvailaibilities()}
                squared={true}
                disabled={!!pickerFocus?true:false}
            />
        );
        if(!formReady) return(
            <PrimaryButton
                value={intl.formatMessage({id:"btn-check-availaibility", defaultMessage: "Vérifier la disponibilité"})}
                setState={()=>checkAvailaibilities()}
                squared={true}
                disabled={true}
            />
        );
    },[formReady,needContact,bookingPrice,prestations,totalTourist,pickerFocus]);

    //HANDLING NBR TOURIST AND PRICE

    useQuery(TOURIST_TAX,{
        variables:{
            articleId:articleData.id
        },
        onCompleted:({ bookingAccommodationTouristTaxGrid: taxOption })=>{
            setTouristTaxOptionId(taxOption.id)
            setTouristTax(extractTouristTaxe(taxOption));
        }
    });

    const calculateNbrOfTourist = (obj:any)=>{
        let nbrTourist = 0;
        Object.entries(obj).map((tourist:any)=>{
            nbrTourist += tourist[1].number;
        });
        return nbrTourist;
    }

    const calculateTouristTaxPrice = (obj:any)=>{
        let price = 0;
        Object.entries(obj).map((item:any)=>{
            price += item[1].number * item[1].price.amount
        });
        return price;
    }

    const extractTouristTaxe = (taxGrid:any)=>{
        let newObject = {} as unknown as any;
        taxGrid.grid.map((item:any)=>{
            switch (item.ageCategory.toLowerCase()) {
                case 'adult': newObject[item.ageCategory.toLowerCase()] = {...item,number:searchData.person.adult || 0};
                    break;
                case 'child': newObject[item.ageCategory.toLowerCase()] = {...item,number:searchData.person.child || 0};
                    break;
                case 'exempt': newObject[item.ageCategory.toLowerCase()] = {...item,number:searchData.person.exempt || 0};
                    break;
                default: newObject[item.ageCategory.toLowerCase()] = {...item,number:0};
                break;
            }
        });
        const totalPrsn = calculateNbrOfTourist(newObject);
        setTotalTourist(totalPrsn);
        setTouristTaxPrice(calculateTouristTaxPrice(newObject));
        return newObject;
    };

    const renderNbrOfTourist = useMemo(()=>{
        if(touristTax === null) return <></>;
        return(
            Object.entries(touristTax).map((array:any)=>{
                const category = array[0];
                const ageRange = (str:string) => {
                    return str.substring(1, str.length - 1).split(',').map(Number);
                };
                return(
                    <div className="nbrPerson-block" key={category}>
                        <p>
                            {
                                category === "adult" && intl.formatMessage({id:"nbrPerson-adult", defaultMessage : "Adultes"})
                            }
                            {
                                category === "child" && intl.formatMessage({id:"nbrPerson-child", defaultMessage : "Enfants"})
                            }
                            {
                                category === "exempt" && intl.formatMessage({id:"nbrPerson-child", defaultMessage : "Enfants"})
                            }
                            <span className="font14">
                                {intl.formatMessage(
                                    {id:`nbrPerson-age-range`, defaultMessage: "De {from} à {to} ans"},
                                    {
                                        from: ageRange(array[1].ageRange)[0],
                                        to: ageRange(array[1].ageRange)[1],
                                    }
                                )}
                            </span>
                        </p>
                        <div className="counter">
                            <button type="button" onClick={()=>handleNbrTourist(category,false)} disabled = {touristTax[category] === 0}>-</button>
                            <span>{touristTax[category].number}</span>
                            <button type="button" onClick={()=>handleNbrTourist(category,true)} disabled = {disabled}>+</button>
                        </div>
                    </div>
                );
            })
        );
    },[touristTax,cartOptions]);

    const handleNbrTourist = (category:string,increment:boolean)=>{
        const clone = {...touristTax};

        ( increment && clone[category].number < 12 ) && clone[category].number++;
        ( !increment && clone[category].number !== 0 ) && clone[category].number--;

        let nbrTourist = calculateNbrOfTourist(clone);
        nbrTourist >= (articleData.features.maxGuests! || 12) ? setDisabled(true) : setDisabled(false); //disable or not buttons

        setTouristTax({...clone});

        !!cartOptions && definePrestationsPrice(nbrTourist)

        document.querySelectorAll(".toggle-btn").forEach((el:any)=>el.checked = true); // set toggle btn to true

        setTotalTourist(nbrTourist); // set total nbr of tourist
        setTouristTaxPrice(calculateTouristTaxPrice(clone)); //Set tourist tax price
    };

    const nbrTouristValue = useCallback(()=>{
        const message:any = [];
        if(touristTax === null) return "";
        if(totalTourist === 0) message.push("");
        Object.entries(touristTax).map((item:any)=>{
            if(item[1].number === 0) return "";
            if(item[0] === "adult") message.push(intl.formatMessage({ id:"nbr-of-adult", defaultMessage: "{adult ,plural, =1 { # adulte } other { # adultes }}"},{adult: item[1].number}));
            if(item[0] === "child") message.push(intl.formatMessage({ id:"nbr-of-child", defaultMessage: "{child ,plural, =1 { # enfant } other { # enfants }}"},{child: item[1].number}));
            if(item[0] === "exempt") message.push(intl.formatMessage({ id:"nbr-of-exempt", defaultMessage: "{exempt ,plural, =1 { # exonéré } other { # exonérés }}"},{exempt: item[1].number}));
        })
        return message;
    },[totalTourist,touristTax]);

    const showNbrTouristWindow = ()=>{
        if(layout.includes("smartphone")) return;
        setNbrTouristWindow(!nbrTouristWindow)
    };

    const extractTouristForMutation = (data: any)=> ({
      touristTax: {
        optionId: touristTaxOptionId,
        participantsCount: Object.values(data)
          .filter((tt: any) => tt.number > 0)
          .map(({ ageCategory, number }: any) => ({ ageCategory, number }))
      }
    });

    //SUPPLEMENTARY PRESTATIONS

    const [getcartOptions,{loading:cartOptionsLoading}] = useLazyQuery (CHECK_OPTIONS_GQL_DATA,{
        onCompleted:(data)=>{
            console.log(data.options)
            setCartOptions(data.options);
            setCalendarOpen(false);
            setFixedCalendarOpen(false);
        },
        onError:(error)=>console.error(error)
    });

    useEffect(()=>{ //Force defining prestations if persons where preselected in search
        if(totalTourist>0 && cartOptions){
            definePrestationsPrice(totalTourist);
        }   
    },[totalTourist,cartOptions])

    useEffect(() => { //Show prestatiosn once totalTourist is over 0, just once.
        if (totalTourist > 0 && !showPrestation) {
            setShowPrestation(true); 
        }
    }, [totalTourist]);

    useEffect(()=>{ // Hide prestations if date are reset
        !bookingPrice && setShowPrestation(false);
    },[bookingPrice]);

    const definePrestationsPrice = useCallback((nbrTourist) => { // Set the prestations array benefit according to totalTourist
        if(nbrTourist < 1) {
            setPrestations([]);
            setPrestationsPrice(0);
        }else{
            let recalculatedPrestations: any[] = [];
            let recalculatedPrice = 0;
            cartOptions.map((e:any)=>{
                const id = e.id; // retrieve id
                const grid = e.grid.find((el:any)=>el.quantity === nbrTourist).price || null;//retrieve grid related to nbr of person
                const price = grid.amount || 0; //retrieve price
                const currency = grid.currency || "CHF"; //retrieve currency
                const newPrestation = {
                    benefit:{
                        optionId:id,
                        price:{
                            amount:price,
                            currency:currency
                        }
                    }
                };
                recalculatedPrestations.push(newPrestation);
                recalculatedPrice += price;
            })
            
            setPrestationsPrice(recalculatedPrice); //set the prestation price for display
            setPrestations(recalculatedPrestations); //set the prestation according to nbr of tourist choosen
        }
    },[cartOptions,touristTax])

    const handlePrestationsChoice = (id:string,event:React.ChangeEvent<HTMLInputElement>)=>{
        const prestationGrid = cartOptions.find((el:any)=>el.id === id).grid || null; //retrieve related prestation with id
        const grid = prestationGrid.find((el:any)=>el.quantity === totalTourist).price || null; //retrieve grid related to nbr of person
        const price = grid.amount || 0; //retrieve price
        const currency = grid.currency || "CHF"; //retrieve currency
        if(event.target.checked){
            const newPrestation = {
                benefit:{
                    optionId:id,
                    price:{
                        amount:price,
                        currency:currency
                    }
                }
            };
            const newPrestationArray = [...prestations,newPrestation];

            setPrestations(newPrestationArray); //set the choosen prestation for cart mutation
            setPrestationsPrice(prestationsPrice + price); //set the prestation price for display
        }else{
            const nextRemovedPrestation = prestations.find((el:any)=>el.benefit.optionId === id);
            const newPrestationArray = [...prestations];
            newPrestationArray.splice(newPrestationArray.indexOf(nextRemovedPrestation),1);
            setPrestations(newPrestationArray);
            setPrestationsPrice(prestationsPrice - price);
        }
    };

    //ANIMATION
    const fixedCalendar = useRef(null);
    const calendar = useRef(null);

    const handleCalendarWindow = (fixed:boolean)=>{
        fixed&&setFixedCalendarOpen(!fixedCalendarOpen)
        !fixed&&setCalendarOpen(false)
    };

    useEffect(()=>{
        if(layout.includes("smartphone")){
            gsap.to(calendar.current,{autoAlpha:1})
        }else if(layout.includes("browser-landscape")&&calendarOpen){
            gsap.to(calendar.current,{autoAlpha:1})
        }else if(layout.includes("browser-landscape")&&!calendarOpen){
            gsap.to(calendar.current,{autoAlpha:0})
        }
    },[calendarOpen,layout]);

    useEffect(()=>{
        if(nbrTouristWindow || layout.includes("smartphone")){
            gsap.to(document.getElementById("nbrTourist"),{autoAlpha:1})
        }else{
            gsap.to(document.getElementById("nbrTourist"),{autoAlpha:0})
        }
    },[nbrTouristWindow,layout]);

    return(
        <>
        {PopUpAddToCart}
        <div className="bookingBox">
            {actionLoading&& <Loader/>}
            {cartOptionsLoading&& <Loader/>}
            <div className="bookingBox-title">
                    <h2 className="bookingBox-title-smartphone">{title()}</h2>
                    <p className="bookingBox-title-smartphone"> {subtitle()}</p>
            </div>
            <form>
                <div className="bookingBox-inputDate">
                    <div className="input-block" >
                        <FontAwesomeIcon icon={faCalendarDays}/>
                        <label htmlFor="arrival">{intl.formatMessage({id:"form-label-arrival", defaultMessage: "Arrivée"})}</label>
                        <input
                            id="arrival"
                            type="text"
                            name="arrival"
                            placeholder={intl.formatMessage({id:"add-date", defaultMessage: "Ajouter une date"})}
                            readOnly={true}
                            value={!!range.start?range.start.format("DD-MM-YYYY"):""}
                            onClick={()=>setCalendarOpen(true)}
                            onChange={resetForm}
                            onMouseLeave={(event) => event.currentTarget.blur()}
                        />
                    </div>
                    <div className="input-block">
                        <FontAwesomeIcon icon={faCalendarDays}/>
                        <label htmlFor="departure">{intl.formatMessage({id:"form-label-departure", defaultMessage: "Départ"})}</label>
                        <input
                            id="departure"
                            type="text"
                            name="departure"
                            placeholder={intl.formatMessage({id:"add-date", defaultMessage: "Ajouter une date"})}
                            readOnly={true}
                            value={!!range.end?range.end.format("DD-MM-YYYY"):""}
                            onClick={()=>setCalendarOpen(true)}
                            onChange={resetForm}
                            // onMouseLeave={(event) => {event.currentTarget.blur(); setCalendarOpen(false)}}
                        />
                    </div>
                </div>

                <div className="bookingBox-calendar" ref={calendar} onMouseLeave={(event) => {event.currentTarget.blur(); setCalendarOpen(false)}}>
                    {layout.includes("browser-landscape")&&
                    <>
                        <FontAwesomeIcon className="bookingBox-title-browser bookingBox-calendar-closeBtn" icon={faXmark} onClick={()=>handleCalendarWindow(false)} />
                        <h2 className="bookingBox-title-browser">{title()}</h2>
                        <p>
                            <FormattedMessage
                                id="searchmodule-choose-dates"
                                defaultMessage="Quelles sont vos dates? <span> Du Samedi au Samedi uniquement</span>"
                                values={{
                                span: (...chunks) => <span>{chunks}</span>,
                                }}
                            />
                        </p>
                    </>
                    }
                    <RangePickerController  selectedRange={range}
                                            pickFocus={pickerFocus}
                                            onSelectedRangeChange={setRange}
                                            onPickFocusChange={setPickerFocus}
                                            numberOfMonths={layout.includes("smartphone")?1:2}
                                            minDate={moment()}
                                            maxDate={dates.end}
                                            isDayBlocked={!!bookingData&&!!bookedDate?isBlockedDate:undefined}
                                            advancedBlockageDisplay={advancedBlockageDisplay}
                    />
                    <span>{intl.formatMessage({id:"contact-for-special-date", defaultMessage:"Pour toute date spéciale n'hésitez pas à nous contacter."})}</span>
                    <div className="bookingBox-calendar-buttons">
                        <button className="bookingBox-deleteDate" type="button" onClick={deleteRange}>
                            {intl.formatMessage({id:"btn-reset-dates", defaultMessage: "Effacer les dates"})}
                        </button>
                        <ButtonBox
                                    value={intl.formatMessage({ id:"btn-save", defaultMessage: "Enregistrer"})}
                                    color="primary"
                                    squared={true}
                                    setState={()=>handleCalendarWindow(false)}
                                    position="end"
                        />
                    </div>
                </div>

                <div className="bookingBox-person" onMouseLeave={()=>layout.includes("smartphone") ? null : setNbrTouristWindow(false)}>
                    <h2>Sélectionnez le nombre de personne</h2>
                    <div className="input-block">
                        <FontAwesomeIcon className="bookingBox-person-users" icon={faUsers}/>
                        <label htmlFor="nbrPerson">{intl.formatMessage({ id:"persons", defaultMessage: "Personnes"})}</label>
                        <input
                        id="nbrPerson"
                        name="nbrPerson"
                        type="text"
                        value={nbrTouristValue()}
                        onClick={showNbrTouristWindow}
                        readOnly
                        placeholder={intl.formatMessage({ id:"booking-box-add-nbrOfPerson", defaultMessage: "Ajouter le nombre de personne(s)"})}
                        onChange={resetForm}
                        onMouseLeave={(event) => event.currentTarget.blur()}
                        />
                        <FontAwesomeIcon className="bookingBox-person-chevron" icon={faChevronDown} style={{transform:nbrTouristWindow?"rotate(180deg)":"rotate(0deg)"}}/>
                    </div>
                    <div id="nbrTourist" className="nbrPerson">
                        {disabled && (
                        <span className="disabled">
                            {intl.formatMessage({id: "maxGuest-span-msg", defaultMessage: "{max} personnes maximum autorisé dans cet établissement"},{max:articleData.features.maxGuests || 12})}
                        </span>
                        )}
                        {renderNbrOfTourist}
                        {
                            <ButtonBox
                                position="end"
                                color="secondary"
                                value={intl.formatMessage({id:"btn-save", defaultMessage: "Enregistrer"})}
                                squared={true}
                                setState={showNbrTouristWindow}
                            />
                        }
                    </div>
                </div>

                {(showPrestation && bookingPrice?.bookable) && (
                <div className="bookingBox-laundry">
                    <div className="input-block">
                        <label>{intl.formatMessage({ id:"form-label-supplementary-prestation", defaultMessage: "Préstations supplémentaires"})}</label>
                        <div className="prestations">
                            {
                                (!cartOptionsLoading&& !!cartOptions)&& cartOptions.map((e:any,index:number)=>{
                                    return (
                                    <div className="prestation" key={index}>
                                        <p>{e.title.value}</p>
                                      {!!e.description &&
                                        <div className="info">
                                          <FontAwesomeIcon icon={faCircleInfo}/>
                                          <div>{parse(e.description.value)}</div>
                                        </div>
                                      }
                                      <label className="switch">
                                          <input className="toggle-btn" type="checkbox" defaultChecked={true}  onChange={(event)=>handlePrestationsChoice(e.id,event)} />
                                          <span className="slider round"></span>
                                      </label>
                                    </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                )}

                <div className="bookingBox-total">
                    {formReady && displayBookingInfo}
                </div>

                <div className="bookingBox-btn">
                    {renderBrowserOuputButton}
                </div>
            </form>
        </div>
        </>
    );
}

export default BookingBox;
